import type React from "react";
import { useEffect, useRef, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import {
  Dialog as AriakitDialog,
  DialogDescription as AriakitDialogDescription,
  DialogDescriptionProps as AriakitDialogDescriptionProps,
  DialogProps as AriakitDialogProps,
  DialogHeading,
  DialogHeadingProps,
  useDialogStore,
  useStoreState,
} from "~/lib/ariakit";

const ANIMATION_DURATION = 100;

const sizes = {
  xs: "sm:max-w-xs",
  sm: "sm:max-w-sm",
  md: "sm:max-w-md",
  lg: "sm:max-w-lg",
  xl: "sm:max-w-xl",
  "2xl": "sm:max-w-2xl",
  "3xl": "sm:max-w-3xl",
  "4xl": "sm:max-w-4xl",
  "5xl": "sm:max-w-5xl",
};

export type DialogProps = {
  /** Controls the maximum width of the dialog based on predefined sizes
   *
   * @default "lg"
   */
  size?: keyof typeof sizes;
  className?: string;
  /** Controls the visibility state of the dialog
   *
   * @example
   * const [open, setOpen] = useState(false);
   *
   * <Dialog open={open} onClose={() => setOpen(false)}>
   *   Content
   * </Dialog>
   */
  open: boolean;
  /** Callback function invoked when the dialog should close
   *
   * Triggered by:
   * - Clicking outside the dialog
   * - Pressing Escape key
   * - setting the `open` prop to `false`
   *
   * @example
   * <Dialog onClose={() => console.log("Dialog closed")}>
   *   Content
   *  <Button onClick={() => setOpen(false)}>Close</Button>
   * </Dialog>
   */
  onClose?: () => void;
  /** Determines if an element inside the dialog will receive focus when shown
   *
   * By default, focuses the first tabbable element or the dialog itself
   *
   * For `Dialog` components taller than the viewport, you may want to set this to `false` to prevent the dialog from focusing a button which is not visible on the screen.
   *
   * @default true
   */
  autoFocusOnShow?: boolean;
  children: React.ReactNode;
} & Omit<AriakitDialogProps, "as" | "className" | "store">;

/**
 * A modal dialog with managed animations and mobile-responsive layout
 *
 * Built on Ariakit's Dialog with added viewport handling and entrance/exit transitions
 */
export function Dialog({
  size = "lg",
  className,
  open,
  onClose,
  autoFocusOnShow,
  children,
  ...props
}: DialogProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const [requiresScroll, setRequiresScroll] = useState(false);

  const dialogStore = useDialogStore({
    open,
    setOpen: (isOpen) => {
      if (!isOpen) onClose?.();
    },
  });

  const mounted = useStoreState(dialogStore, "mounted");

  useEffect(() => {
    const container = containerRef.current;
    const dialog = dialogRef.current;

    if (!mounted || !container || !dialog) return;

    const resizeObserver = new ResizeObserver(() => {
      const needsScroll = dialog.clientHeight > container.clientHeight;
      // `overflow-y` on AriakitDialog wrapper div is `hidden` for dialogs shorter than
      // the viewport and `auto` for those taller to avoid extraneous scrollbar
      // and horizontal shift during entrance animation
      setRequiresScroll(needsScroll);
    });

    resizeObserver.observe(dialog);

    // Dialog taller than viewport is scrolled to top on enter for clear readability
    // Otherwise, it renders vertically-centered on viewport with top content cut off
    let requestId: ReturnType<typeof requestAnimationFrame> | null = null;

    if (open) {
      requestId = requestAnimationFrame(() => {
        container.scrollTop = 0;
      });
    }

    return () => {
      resizeObserver.disconnect();
      if (requestId !== null) cancelAnimationFrame(requestId);
    };
  }, [mounted, open]);

  if (!mounted) return null;

  return (
    <div
      ref={containerRef}
      className={twJoin(
        "fixed inset-0 z-50 w-screen",
        requiresScroll ? "overflow-y-auto" : "overflow-y-hidden",
        "pt-6 sm:pt-0",
      )}
    >
      <div className="grid min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[1fr_auto_3fr] sm:p-4">
        <AriakitDialog
          ref={dialogRef}
          store={dialogStore}
          backdrop={
            <div className="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-zinc-950/25 p-2 opacity-0 transition duration-100 data-[enter]:opacity-100 data-[enter]:ease-out data-[leave]:ease-in focus:outline-0 sm:px-6 sm:py-8 lg:px-8 lg:py-16" />
          }
          portal={false}
          className={twMerge(
            className,
            sizes[size],
            "row-start-2 w-full min-w-0 rounded-t-3xl bg-white p-[--gutter] shadow-lg ring-1 ring-zinc-950/10 [--gutter:theme(spacing.8)] sm:mb-auto sm:rounded-2xl forced-colors:outline",
            "translate-y-12 opacity-0 transition will-change-transform data-[enter]:translate-y-0 data-[enter]:scale-100 data-[leave]:scale-100 data-[enter]:opacity-100 data-[enter]:ease-out data-[leave]:ease-in sm:translate-y-0 sm:scale-95",
          )}
          style={{ transitionDuration: `${ANIMATION_DURATION}ms` }}
          {...props}
        >
          {children}
        </AriakitDialog>
      </div>
    </div>
  );
}

/** Semantic heading component for dialog titles */
export function DialogTitle({
  className,
  ...props
}: { className?: string } & Omit<DialogHeadingProps, "as" | "className">) {
  return (
    <DialogHeading
      {...props}
      className={twMerge(
        className,
        "text-balance text-lg/6 font-semibold text-zinc-950 sm:text-base/6",
      )}
    />
  );
}

/** Descriptive text component for dialogs */
export function DialogDescription({
  className,
  ...props
}: { className?: string } & Omit<
  AriakitDialogDescriptionProps,
  "as" | "className"
>) {
  return (
    <AriakitDialogDescription
      {...props}
      className={twMerge(
        className,
        "mt-2 text-pretty text-base/6 text-zinc-500 sm:text-sm/6",
      )}
    />
  );
}

/** Container for main dialog content */
export function DialogBody({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={twMerge(className, "mt-6")} />;
}

/**
 * Container for dialog action buttons
 *
 * Handles responsive button layout and spacing
 */
export function DialogActions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      {...props}
      className={twMerge(
        className,
        "mt-8 flex flex-col-reverse items-center justify-end gap-3 *:w-full sm:flex-row sm:*:w-auto",
      )}
    />
  );
}
