"use client";

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button } from "~/ui/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "~/ui/dialog";

/**
 * Current version of the app that is running
 */
let version: string | null = null;

/**
 * Interval time in seconds to check for new version
 * By default, it is set to 120 seconds
 */
const VERSION_UPDATER_REFETCH_INTERVAL_SECONDS = 120;

function useVersionUpdater(props: { intervalTimeInSecond?: number } = {}) {
  const interval = VERSION_UPDATER_REFETCH_INTERVAL_SECONDS;
  const refetchInterval = (props.intervalTimeInSecond ?? interval) * 1000;

  // start fetching new version after half of the interval time
  const staleTime = refetchInterval / 2;

  return useQuery({
    queryKey: ["version-updater"],
    staleTime,
    gcTime: refetchInterval,
    refetchIntervalInBackground: true,
    refetchInterval,
    initialData: null,
    queryFn: async () => {
      const response = await fetch("/api/version");
      const currentVersion = await response.text();
      const oldVersion = version;
      version = currentVersion;
      const didChange = oldVersion !== null && currentVersion !== oldVersion;
      return {
        currentVersion,
        oldVersion,
        didChange,
      };
    },
  });
}

export function VersionUpdater(props: { intervalTimeInSecond?: number }) {
  const { data } = useVersionUpdater(props);
  const [dismissed, setDismissed] = useState(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  useEffect(() => {
    setShowDialog(data?.didChange ?? false);
  }, [data?.didChange]);

  if (!data?.didChange || dismissed) {
    return null;
  }

  return (
    <Dialog size="md" open={showDialog} onClose={() => setShowDialog(false)}>
      <DialogTitle>New version available</DialogTitle>
      <DialogDescription>
        A new version of the app is available. It is recommended to refresh the
        page to get the latest updates and avoid any issues.
      </DialogDescription>
      <DialogActions>
        <Button
          variant="outline"
          onClick={() => {
            setShowDialog(false);
            setDismissed(true);
          }}
        >
          Back
        </Button>
        <Button onClick={() => window.location.reload()}>
          Reload and update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
